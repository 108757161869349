import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { HtmlSerializer } from '../utils/htmlSerializer'

export const EmphasisSection = ({ slice }) => {
  const id = slice.primary.activity_anchor || ""
  return (
    <section className="emphasis_section" >
      <div className="anchor" id={id}/>
      <div className="text_box">
        <PrismicRichText 
          field={slice.primary.text_field.richText}
          components={HtmlSerializer}
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ModuleLessonDataBodyEmphasisSection on PrismicModuleLessonDataBodyEmphasisSection {
    primary {
      text_field {
        richText
      }
      activity_anchor
    }
  }
`
