import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { HtmlSerializer } from '../utils/htmlSerializer'

export const AudioSection = ({ slice }) => {
  const id = slice.primary.activity_anchor || ""
  return (
    
    <section className="audio_section">
      <div className="anchor" id={id}/>
      <div className="title_box">
        <PrismicRichText 
          field={slice.primary.section_header.richText} 
          components={HtmlSerializer}
        />
      </div>
      <div className="audio_box">
        <div className="inner_wrap">
          <div dangerouslySetInnerHTML={{ __html: slice.primary.audio_url?.html }} />
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ModuleLessonDataBodyAudioSection on PrismicModuleLessonDataBodyAudioSection {
    primary {
      section_header {
        richText
      }
      audio_url {
        html
        embed_url
      }
      activity_anchor
    }
  }
`
