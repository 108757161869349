import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicText } from '@prismicio/react'
import { HtmlSerializer } from '../utils/htmlSerializer'

export const VideoSection = ({ slice }) => {
  const id = slice.primary.activity_anchor || ""
  return (
    <section className="video_section">
      <div className="anchor" id={id}/>
      <div className="title_box">
        <PrismicRichText 
          field={slice.primary.section_header.richText} 
          components={HtmlSerializer}
        />
      </div>
      <div className="video_box">
        <div className="inner_wrap">
          <div dangerouslySetInnerHTML={{ __html: slice.primary.video_url?.html }} />
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ModuleLessonDataBodyVideoSection on PrismicModuleLessonDataBodyVideoSection {
    primary {
      section_header {
        richText
      }
      video_url {
        embed_url
        html
        title
      }
      activity_anchor
    }
  }
`
