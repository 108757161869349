import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const Prizes = ({ slice }) => {
  return (
    <section className="prizes">
      <div className="Container">
        <div className="wrap">
          <PrismicRichText field={slice.primary.header.richText} />
          <div className="flex_container">
            {slice.items.map((galleryItem, index) => (
              <div className="flex_col">
              <GatsbyImage
                image={galleryItem.item_icon?.gatsbyImageData}
                placeholder="blurred"
                alt={galleryItem.item_icon?.alt || ""}
              />
                <PrismicRichText field={galleryItem.item_header.richText} />
                <PrismicRichText field={galleryItem.item_text.richText} />
              </div>
            ))}
          </div>
        </div>
        <div className="button_box">
          <Link className="button_primary" to={slice.primary.button.url}>
            {slice.primary.button_text}
          </Link>
          <Link className="button_secondary" to={slice.primary.second_button.url}>
            {slice.primary.button_2_text}
          </Link>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPrizes on PrismicHomepageDataBodyPrizes {
    primary {
      header {
        richText
      }
      button{
        url
      }
      button_text
      second_button{
        url
      }
      button_2_text
    }
    items{
      item_icon{
        url
        alt
        gatsbyImageData(
          width: 53
        )
      }
      item_header{
        richText
      }
      item_text{
        richText
      }
    }
  }
`
