import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicText, PrismicLink  } from '@prismicio/react'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export const CourseOutline = ({ slice }) => {
  return (
    <section className="course_outline">
      <div className="Container">
        <div className="content">
          <PrismicRichText field={slice.primary.header.richText} />
          <PrismicLink className="button_primary start_button" href={slice.primary.start_button?.url}>
            {slice.primary.start_button_text}
            
          </PrismicLink>
          {slice.primary.button_text && 
          <div className="button_box">
            <div className="dropdown">
              <button className="drop_btn">{slice.primary.button_text}</button>
              <div className="drop_content">
                <PrismicLink className="page_link" href={slice.primary.button?.url}>
                  {slice.primary.button_text}
                </PrismicLink>
                <PrismicLink className="page_link" href={slice.primary.button_2?.url}>
                  {slice.primary.button_text}
                </PrismicLink>
              </div>
            </div>
          </div>
          }
          <PrismicRichText field={slice.primary.text.richText} />
        </div>
        <div className="module_list">
          <Tabs>
            <TabList>
              {slice.items.map((galleryItem, index) => (
                <Tab>
                  <PrismicText field={galleryItem.list_header.richText} />  
                </Tab>
              ))}
            </TabList>
            {slice.items.map((galleryItem, index) => (
              <TabPanel>
                <div className="module">
                  <div className="image_box">
                    <GatsbyImage
                      image={galleryItem.item_image.gatsbyImageData}
                      alt={galleryItem.item_image.alt || ""}
                      className="module_image"
                    />
                  </div>
                  <PrismicRichText field={galleryItem.item_header.richText} />
                  <PrismicRichText field={galleryItem.item_text.richText} />
                  <div className="button_box">
                    <PrismicLink className="button_secondary" href={galleryItem.item_button_a?.url}>
                      {galleryItem.download_link}
                    </PrismicLink>

                    <PrismicLink className="button_primary" href={galleryItem.module_link.document?.url}>
                      {galleryItem.module_link_text}
                    </PrismicLink>
                  </div>
                </div>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCourseOutline on PrismicHomepageDataBodyCourseOutline {
    primary {
      header {
        richText
      }
      button{
        url
        target
      }
      button_2{
        url
        target
      }
      button_text
      text {
        richText
      }
      start_button{
        url
      }
      start_button_text
    }
    items{
      item_image{
        url
        alt
        gatsbyImageData
      }
      item_header{
        richText
      }
      list_header{
        richText
      }
      item_text{
        richText
      }
      item_button_a{
        url
      }
      download_link
      module_link{
        document{
          ... on PrismicModuleOverview {
            id
            url
            type
          }
        }
      }
      module_link_text
    }
  }
`
