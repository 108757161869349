import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const FaqTemplate = ({ data }) => {
  if (!data) return null

  const faqContent = data.prismicFaq
  const faq = faqContent.data || {}

  const { lang, type, url } = faqContent
  const display_title = faq.display_title?.text || ""
  const alternateLanguages = faqContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="Container">
        <div className="faq-title title"><PrismicRichText field={faq.header.richText}/></div>
        <SliceZone slices={faq.body} components={components} />
      </div>  
    </Layout>
  )
}

export const query = graphql`
  query faqQuery($id: String, $lang: String) {
    prismicFaq(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        
        header{
          richText
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...FaqDataBodyFaqSection
        }
        display_title{
          text
        }
      }
        
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang}) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(FaqTemplate)
