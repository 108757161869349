import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const HowItWorks  = ({ slice }) => {
  return (
    <section className="how_it_works">
      <div className="Container">
        <PrismicRichText field={slice.primary.header.richText} />
        <div className="wrap">
          {slice.items.map((galleryItem, index) => (
            <div className="flex_container">
              <div className="flex_content">
                <PrismicRichText field={galleryItem.item_header.richText} />
                <PrismicRichText field={galleryItem.item_text.richText} />
              </div>
              <div className="flex_image">
                <GatsbyImage
                  image={galleryItem.item_image.gatsbyImageData}
                  alt={galleryItem.item_image.alt || ""}
                  className="how_it_works_image"
                  placeholder="blurred"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHowItWorks on PrismicHomepageDataBodyHowItWorks {
    primary {
      header{
        richText
      }
    }
    items {
      item_image{
        url
        alt
        gatsbyImageData(
          width: 307
        )
      }
      item_header{
        richText
      }
      item_text{
        richText
      }
    }
  }
`
