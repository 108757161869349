import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Layout } from '../components/Layout'
import { LessonLayout } from '../components/LessonLayout'
import { components } from '../slices'
import { HtmlSerializer } from '../utils/htmlSerializer'

const ModuleLessonTemplate = ({ data, activeDocMeta }) => {
  if (!data) return null

  const moduleContent = data.prismicModuleLesson
  const module = moduleContent.data || {}

  const lessonsContent = data.allPrismicModuleLesson
  const lessons = lessonsContent.nodes || {}

  const homepageContent = data.allPrismicHomepage
  const homepage = homepageContent.nodes

  const prevPage = lessons[module.lesson_number-2]?.url
  const nextPage = lessons[module.lesson_number]?.url

  function page(index){
    if (module.lesson_number === index+1) return "cur"
    else return "other"
  }
  function prev_Page(){
    if (module.lesson_number-1 > 0)return "button_active" 
      else return "button_not_active"   
  }
  function disabled(){
    if (module.lesson_number-1 > 0)return "false" 
      else return "true"   
  }
  function next_Page(){
    if (module.lesson_number < lessons.length) return "button_active"
    else return "button_not_active"
  }
  function disabledNext(){
    if (module.lesson_number < lessons.length) return "false"
    else return "true"
  }

  function curLangIntro(site, curLang){
    if(lang === 'fr-ca') return "intro"
    else return "Intro"
  }

  function curLangNext(site, curLang){
    if(lang === 'fr-ca') return "Section suivante"
    else return "Next Section"
  }

  function curLangBack(site, curLang){
    if(lang === 'fr-ca') return "Retourner"
    else return "Go Back"
  }
  
  function curLangExit(site, curLang){
    if(lang === 'fr-ca') return "Sortir"
    else return "Exit"
  }
  function curLangSkip(site, curLang){
    if(lang === 'fr-ca') return "Aller au contenu"
    else return "Skip to Content"
  }
  function curLangLogo(site, curLang){
    if(lang === 'fr-ca') return <StaticImage
                src="../images/yvip-fr-logo.png"
                alt="Logo Back to Home"
                placeholder="none"
                loading="eager"
                className="home_logo"
                width={245}
              />
    else return <StaticImage
                src="../images/Your-Voice-is-Power-RGB.png"
                alt="logo Back to Home"
                placeholder="none"
                loading="eager"
                className="home_logo"
                width={245}
              />
  }
  
  const { lang, type, url } = moduleContent
  const display_title = module.display_title?.text || ""
  const alternateLanguages = moduleContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }

  var lessonNum = 0
  function lessonCounter(){
    lessonNum = lessonNum+1
    return lessonNum
  }
  var moduleNum = 0
  function moduleCounter(){
    moduleNum = moduleNum+1
    return "Module "+moduleNum
  }
  var actNum = 0
  function ifFrench(){

    actNum=actNum+1
    if (lang==="fr-ca") var actLang = "#activité"
    else var actLang = "#activity"
    return actLang+actNum
  }

  return (
    <LessonLayout activeDocMeta={activeDoc}>
      <section className="module_lesson">
        <a className="skip_button" href="#main">   
          {curLangSkip()}
        </a>
        <div className="topbar">
         <div className="title">
            <span>{module.parent?.document.data.title.text}</span>
          </div>
          <div className="top_btns">
            <div className="dropdown">
              <button className="drop_btn">Modules</button>
              <div className="drop_content modules">
                <Link className="page_link" to="/page/module-intro">
                  Module {curLangIntro()}
                </Link>
                {data.allPrismicModuleOverview.nodes.map((module,index) => (
                  <Link className="page_link" to={module.url}>
                     {moduleCounter()}
                  </Link>
                ))}
              </div>
            </div>
            <Link className="module_button b1" to={module.parent?.document.url}>{curLangExit()}</Link>
          </div>
        </div>
        <div className="sidebar">
          {homepage.map((id,index) => (
            <Link className="logo_box" to={id.url}>
              {curLangLogo()}
            </Link>
          ))}
         
          {lessons.map((lesson,index) => (
            <div className="item" id={page(index)}>
              <PrismicRichText 
                key={lesson.id} 
                field={lesson.data.lesson_list_title?.richText} 
                components={{
                  heading3: ({ children }) => <Link to={lesson.url}><div className="counter">{lessonCounter()}</div><h3  className="item_header"> {children} </h3></Link>,
                  paragraph: ({ children }) => <Link to={ifFrench()}> <p>{children} </p></Link>,
                }}
              />
            </div>
          ))}
        </div>
        <div id="main">
          <div className="lesson_slices">
            <SliceZone slices={module.body} components={components} />
          </div>
        </div>
        <div className="bottombar">
          <Link className="module_button b2" aria-disabled={disabled()} id={prev_Page()} to={prevPage} >&#x2190; {curLangBack()}</Link>
          <Link className="module_button b3" aria-disabled={disabledNext()} id={next_Page()} to={nextPage} >{curLangNext()}  &#x2192;</Link>
        </div>
      </section>
    </LessonLayout>
  )
}

export const query = graphql`
  query module_lessonQuery($id: String, $lang: String, $parent: String) {
    prismicModuleLesson(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        parent{
          url
          link_type
          document {
            ... on PrismicModuleOverview {
              url
              data {
                module_number
                title {
                  text
                }
              }
            }  
          }
        }
        module_title{
          richText
        }
        lesson_list_title{
          richText
        }
        lesson_number
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...ModuleLessonDataBodyAudioSection
          ...ModuleLessonDataBodyCodeSection
          ...ModuleLessonDataBodyEmphasisSection
          ...ModuleLessonDataBodyImageText
          ...ModuleLessonDataBodyTextSection
          ...ModuleLessonDataBodyVideoSection
          ...ModuleLessonDataBodyNotesSection
        }
        display_title{
          text
        }
      }
    }
    allPrismicModuleLesson(
      filter: {data: { parent: {uid: {eq: $parent}}}, lang: { eq: $lang} }
      sort: {fields: data___lesson_number, order: ASC}) {
      nodes {
        url
        data{
          lesson_number
          lesson_list_title{
            richText
          }
        }
      }
    }
    allPrismicModuleOverview(
      sort: {fields: uid, order: ASC}
      filter: {lang: { eq: $lang}}) { 
      nodes {
        url
        uid
      }
    }
    allPrismicHomepage(
      filter: {lang: { eq: $lang}}) {
      nodes {
        url
      }
    }
  }


`

export default withPrismicPreview(ModuleLessonTemplate)
