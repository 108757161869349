import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { Footer } from './Footer'

import ogImage from "../images/meta-image.jpg"

export const Layout = ({ children, topMenu, bottomMenu, activeDocMeta }) => {

  
  function curLangImage(site, curLang){
    if(activeDocMeta.lang === 'fr-ca') return "https://images.prismic.io/yourvoiceispower/76834135-5dd5-49dd-a2bf-87de1f994efa_meta-fr.jpg"
    else if(activeDocMeta.lang === 'iu-ca') return "https://images.prismic.io/yourvoiceispower/6dcf1498-9d9d-45c1-8bf4-40f2c40672aa_meta-iu.jpg"
    else if(activeDocMeta.lang === 'oj-ca') return "https://images.prismic.io/yourvoiceispower/09237f2b-fa33-41d7-81d4-f96f733dee61_meta-oj.jpg"
    else return "https://images.prismic.io/yourvoiceispower/f4690b8f-a0fc-4672-97cc-22df079ff5fe_meta-image.jpg"
  }
  
  const pageTitle = activeDocMeta.display_title || ""
  return (
    <StaticQuery 
      query={graphql`
        query SiteQuery {
          site {
            siteMetadata {
              title
              titleFr
              description
              image
              siteUrl
              twitterUsername
            }
          }
        }
      `}

      render={data => (
        <>
          <Helmet
            htmlAttributes={{
              lang: activeDocMeta.lang
            }}
          >
            <meta charSet="utf-8" />

            <title>{pageTitle}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />

            <meta name="image" content={curLangImage()} />
      
            <meta property="og:url" content={data.site.siteMetadata.siteUrl} />}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={data.site.siteMetadata.description} />
            <meta property="og:image" content={curLangImage()} />
            
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={data.site.siteMetadata.twitterUsername} />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={data.site.siteMetadata.description} />
            <meta name="twitter:image" content={curLangImage()} />

            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            
            <link async defer href="https://static.cdn.prismic.io/prismic.js?new=true&repo=yourvoiceispower"/>

            <link 
              rel="stylesheet"
              href="https://use.typekit.net/htm6zgt.css"
            />
            <link
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
              rel="stylesheet"
            />
            
          </Helmet>
          <TopMenu topMenu={topMenu} activeDocMeta={activeDocMeta} />
          <main id="main">{children}</main>
          <Footer bottomMenu={bottomMenu} activeDocMeta={activeDocMeta} />
        </>
      )}
    />
  )
}
