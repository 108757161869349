import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const VideoAndText = ({ slice }) => {
  return (
    <section className="video_and_text">
      <div className="Container">
        <div className="flex_container">
          <div className="flex_video">
            
            <div dangerouslySetInnerHTML={{ __html: slice.primary.video?.html }} />

          </div>
          <div className="flex_content">
            <PrismicRichText field={slice.primary.header.richText} />
            <PrismicRichText field={slice.primary.text.richText} />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyVideoAndText on PrismicHomepageDataBodyVideoAndText {
    primary {
      video {
        embed_url
        html
      }
      header {
        richText
      }
      text {
        richText
      }
    }
  }
`
