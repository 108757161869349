import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const Sponsors = ({ slice }) => {
  return (
    <section className="sponsors">
      <div className="Container">
        <PrismicRichText field={slice.primary.header.richText} />
        <div className="icons">
          {slice.items.map((galleryItem, index) => (
            <PrismicLink href={galleryItem.sponsor_link?.url} target={galleryItem.sponsor_link.target}>
              <GatsbyImage
                image={galleryItem.image.gatsbyImageData}
                alt={galleryItem.image.alt || ""}
                className="icon"
                objectFit="contain"
              />
            </PrismicLink>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySponsors on PrismicHomepageDataBodySponsors {
    primary {
      header {
        richText
      }
    }
    items{
      sponsor_link{
       url
       target
      }
      image{
        url
        alt
        gatsbyImageData(
          width: 180
        )
      }
      sponsor_link{
        url
      }
    }
  }
`
