import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { Footer } from './Footer'

export const LessonLayout = ({ children,  activeDocMeta }) => {
  function curLangTitle(site){
    if(activeDocMeta.lang === "fr-ca") return site.titleFr
    else return site.title
  }

  const pageTitle = activeDocMeta.display_title || ""
  return (
    <StaticQuery 
      query={graphql`
        query LessonSiteQuery {
          site {
            siteMetadata {
              title
              titleFr
              description
              image
              siteUrl
              twitterUsername
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            htmlAttributes={{
              lang: activeDocMeta.lang
            }}
          >
            <meta charSet="utf-8" />
            <title>{curLangTitle(data.site.siteMetadata)} | {pageTitle}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <meta name="image" content={data.site.siteMetadata.image} />
      
            <meta property="og:url" content={data.site.siteMetadata.siteUrl} />}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={data.site.siteMetadata.description} />
            <meta property="og:image" content={data.site.siteMetadata.image} />
            
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={data.site.siteMetadata.twitterUsername} />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={data.site.siteMetadata.description} />
            <meta name="twitter:image" content={data.site.siteMetadata.image} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link
              href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900"
              rel="stylesheet"
              type="text/css"
            />
            <link 
              rel="stylesheet"
              href="https://use.typekit.net/htm6zgt.css"
            />
            <link
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
              rel="stylesheet"
            />
            
            <script async defer src="https://static.cdn.prismic.io/prismic.js?new=true&repo=yourvoiceispower"></script>
          </Helmet>
          <main>{children}</main>
        </>
      )}
    />
  )
}
