import * as React from 'react'
import { StaticQuery,graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicLink, PrismicText } from '@prismicio/react'

import twitterIcon from '../images/twitter.svg'
import instagramIcon from '../images/instagram.svg'
import facebookIcon from '../images/facebook.svg'


export const Footer = ({ bottomMenu, activeDocMeta }) => {
  const image = bottomMenu.logo
  const mod_str = bottomMenu.module || "Module"

  const currentLang = (activeDocMeta.lang==='fr-ca')
    ? 'fr-ca'
    : 'en-ca'
  var moduleNum = 0
  function moduleCounter(){
    moduleNum = moduleNum+1
    return mod_str+" "+moduleNum
  } 
  function reduced(modules){
    var info = modules.filter(function(module){
      if (module.lang === currentLang ) return true
      else return false
    })
    return info
  }
  function curLangSubmitLink(site, curLang){
    if(activeDocMeta.lang === 'fr-ca') return "https://www.rocketjudge.com/register/YVIPCanada-french"
    else return "https://www.rocketjudge.com/register/YVIPCanada-english"
  }
  function curLangIntro(site, curLang){
    if(activeDocMeta.lang === 'fr-ca') return "intro"
    else return "Intro"
  }
  return (
    <StaticQuery 
      query={graphql`
        query FooterQuery {
          allPrismicModuleOverview(
            sort: {fields: uid, order: ASC}) {
            nodes {
              url
              uid
              lang
            }
          }
        }
      `}
      render={data => (
        <footer>
          <div className="Container">
            <nav className="footer_menu">
              <div className="nav_logo">
                <GatsbyImage
                  image={image?.gatsbyImageData}
                  alt={image?.alt || ""}
                  className="logo"
                  loading="eager"
                  placeholder="blurred"
                />
              </div>
              <div className="nav_col contact">
                <div className="social">
                  <a href="https://www.facebook.com/takingitglobal" target="_blank" rel="noopener"><img src={facebookIcon} alt="Facebook social link" className="icon"/></a>
                  <a href="https://instagram.com/takingitglobal" target="_blank" rel="noopener"><img src={instagramIcon} alt="Instagram social link" className="icon"/></a>
                  <a href="https://twitter.com/CanCodeToLearn" target="_blank" rel="noopener"><img src={twitterIcon} alt="Twitter social link" className="icon"/></a>
                </div>
                <a href="https://earsketch.gatech.edu/earsketch2/" target="_blank" rel="noopener">{bottomMenu.start_coding_header}</a>
                <a href={curLangSubmitLink()} className="page_link" target="_blank" rel="noopener">{bottomMenu.submit_header}</a>
                {bottomMenu.pages?.map((page, index) => (
                  <PrismicLink className="page_link" target={page.item_link.target} href={page.item_link?.url} key={"footer"+index}>
                    {page.item_text}
                  </PrismicLink>
                ))}
              </div>
              <div className="nav_col resources">
                <h3>{bottomMenu.resources_header}</h3>
                {bottomMenu.resources?.map((page, index) => (
                  <PrismicLink className="page_link" target={page.item_link?.target} href={page.item_link?.url} key={"footer_2"+index}>
                    {page.item_text}
                  </PrismicLink>
                ))}
              </div>
              <div className="nav_col modules">
                <h3>{bottomMenu.modules_header}</h3>
                <Link className="page_link item" to="/page/module-intro">
                  Module {curLangIntro()}
                </Link>
                {reduced(data.allPrismicModuleOverview.nodes).map((module,index) => (
                    <Link className="item" to={module.url} key={"footer_3"+index}>
                      {moduleCounter()} 
                    </Link>
                  ))}
              </div>    
            </nav>
            <div className="copyright">
              <PrismicLink href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank">
                <StaticImage
                  src="../images/cc.png"
                  alt="Creative Commons License"
                  className="cc"
                  width={88}
                />
              </PrismicLink>
            </div>
          </div> 
        </footer>
      )}
    />
  )
}

export const query = graphql`
  fragment BottomMenuFragment on PrismicBottomMenu {
    _previewable
    type
    lang
    data{
      logo {
        url
        alt
        gatsbyImageData(
          width: 350
        )
      }
      resources_header
      modules_header
      module
      submit_header
      start_coding_header

      resources{
        item_text
        item_link{
          url
          target
          link_type
        }
      }

      pages{
        item_text
        item_link{
          url
          link_type
          target
        }
      }
    }
  }
`
