import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { HtmlSerializer } from '../utils/htmlSerializer'

export const CurriculumSection = ({ slice }) => {
  return (
    <section className="curriculum_section" >
      <details className="province___details">
        <summary className="province___summary"><h3>{slice.primary.province}</h3></summary>
        {slice.items.map((galleryItem, index) => (
          <div className="curriculum" key={"curriculum-"+index}>
            <details className="curriculum___details">
              <summary className="curriculum___subject">{galleryItem.subject}</summary>
              <PrismicRichText 
                field={galleryItem.standards?.richText} 
                className="curriculum___standards"
                components={HtmlSerializer}
              />
            </details>
          </div>
        ))}
      </details>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyCurriculumSection on PrismicPageDataBodyCurriculumSection {
    primary {
      province 
    }
    items {
      subject
      standards {
        richText
      }
    }
  }
`
