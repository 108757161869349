import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const PageTextSection = ({ slice }) => {
  return (
    <section className="text_section">
      <div className="text_box">
        <PrismicRichText field={slice.primary.text_field.richText}/>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyTextSection on PrismicPageDataBodyTextSection {
    primary {
      text_field {
        richText
      }
    }
  }
`

