import * as React from 'react'
import { Link } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import { PrismicLink } from '@prismicio/react'

export const HtmlSerializer = (type, element, content, children, key) => {
  if (element.type === "hyperlink" && element.data.link_type === 'Document') {
    return (
      <PrismicLink href={element.data.uid} className="internal">{ children }</PrismicLink>
    )
  }
  return null
}

export default HtmlSerializer