import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

export const TrainingTab = ({ slice }) => {
  return (
    <div className="tab_container">
      <div className="content">
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
      <div className="flex_list">
        {slice.items.map((galleryItem, index) => (
          <div className="item">
            <div className="date_box">
              <PrismicRichText field={galleryItem.item_time.richText}/>
            </div>
            <details>
              <summary>
                <PrismicRichText field={galleryItem.item_header.richText}/>
                <div class="summary-chevron-up">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" 
                  stroke="#742F8A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                  <polyline points="6 9 12 15 18 9"></polyline></svg>
                </div>
              </summary>
              <PrismicRichText field={galleryItem.item_text.richText}/>
              <PrismicLink href={galleryItem.item_link?.url}>
                {galleryItem.item_link_text}
              </PrismicLink>

              <div className="code"><PrismicRichText field={galleryItem.item_code?.richText}/></div>
              <div class="summary-chevron-down">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" 
                stroke="#742F8A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" 
                class="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
              </div>
            </details>
          </div>
        ))}
      </div>
    </div>
  
  )
}

export const query = graphql`
  fragment TeacherTrainingDataBodyTrainingTab on PrismicTeacherTrainingDataBodyTrainingTab {
    primary {
      text{
        richText
      }
    }
    items{
      item_time{
        richText
      }
      item_header{
        richText
      }
      item_text{
        richText
      }
       item_link_text
      item_link{
        url
      }
      item_code{
        richText
      }
    }
  }
  fragment TeacherTrainingDataBody1TrainingTab on PrismicTeacherTrainingDataBody1TrainingTab {
    primary {
      text{
        richText
      }
    }
    items{
      item_time{
        richText
      }
      item_header{
        richText
      }
      item_text{
        richText
      }
      item_link{
        url
      }
      item_code{
        richText
      }
    }
  }

`
