import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const ImageChecklist  = ({ slice }) => {
  const featuredImage = slice.primary.image
  return (
    <section className="image_checklist">
      <div className="Container">
        <div className="flex_container">
          <div className="flex_content">
            {slice.items.map((galleryItem, index) => (
              <div className="flex_col">
                <PrismicRichText field={galleryItem.item_header.richText} />
                <PrismicRichText field={galleryItem.item_text.richText} />
                <a href={galleryItem.item_link.url}><PrismicRichText field={galleryItem.item_link_text.richText} /></a>
              </div>
            ))}
          </div>
          <div className="flex_image">
            <GatsbyImage
              image={featuredImage?.gatsbyImageData}
              alt={featuredImage?.alt || ""}
              className="checklist_image"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment TeacherChecklistDataBodyImageChecklist on PrismicTeacherChecklistDataBodyImageChecklist {
    primary {
      image{
        url
        alt
        gatsbyImageData
      }
    }
    items {
      item_header{
        richText
      }
      item_text{
        richText
      }
      item_link{
        url
      }
      item_link_text{
        richText
      }
    }
  }
`
