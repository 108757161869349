import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Layout } from '../components/Layout'
import { components } from '../slices'

const TeacherTrainingTemplate = ({ data }) => {
  if (!data) return null

  const teacherContent = data.prismicTeacherTraining
  const teacher = teacherContent.data || {}

  const { lang, type, url } = teacherContent
  const display_title = teacher.display_title?.text || ""
  const alternateLanguages = teacherContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <section className="teacher_training">
        <div className="Container">
          <div className="title">
            <PrismicRichText field={teacher.title.richText}/>
          </div>
          <Tabs>
            <TabList>
              <Tab><PrismicRichText field={teacher.tab_1_title.richText}/></Tab>
              <Tab><PrismicRichText field={teacher.tab_2_title.richText}/></Tab>
            </TabList>
            <TabPanel>
              <SliceZone slices={teacher.body} components={components} />
            </TabPanel>
            <TabPanel>
              <SliceZone slices={teacher.body1} components={components} />
            </TabPanel>
          </Tabs>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query teacher_trainingQuery($id: String, $lang: String) {
    prismicTeacherTraining(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title{
          richText
        }
        tab_1_title{
          richText
        }
        tab_2_title{
          richText
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...TeacherTrainingDataBodyTrainingTab
        }
        body1 {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...TeacherTrainingDataBody1TrainingTab
        }
        display_title{
          text
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang}) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(TeacherTrainingTemplate)
