import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const JudgesTemplate = ({ data }) => {

  const judgesContent = data.prismicJudges
  const judges = judgesContent.data || {}
  const display_title = judges.display_title?.text || ""
  const { lang, type, url } = judgesContent
  const alternateLanguages = judgesContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  const readMore = judges.read_more || "Read More"
  const showLess = judges.read_less || "Show Less"
  
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 330) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? `  ${readMore}...` : `  ${showLess}`}
      </span>
    </p>
  );
};
  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="JudgesPage">
        <div className="Container">
        
          <div className="JudgesPage___hero">
            <div className="JudgesPage___copy">
              <PrismicRichText 
                field={judges.hero_content.richText}
              />
              <div className="btn-container">
                
                {judges.hero_link_label_1 && 
                <PrismicLink 
                  className="button_primary"
                  href={judges.hero_link_1?.url || "#"} 
                >
                  {judges.hero_link_label_1}
                </PrismicLink>
                }
                {judges.hero_link_label_2 && 
                <PrismicLink 
                  className="button_secondary"
                  href={judges.hero_link_2?.url || "#"} 
                >
                  {judges.hero_link_label_2}
                </PrismicLink>
                }
              </div>
            </div>
            <GatsbyImage
              image={judges.hero_image.gatsbyImageData}
              loading="eager"
              objectFit="contain"
              alt={judges.hero_image?.alt || ""}
              className="JudgesPage___image-wrap"
            />
          </div>
          <div className="JudgesPage___bios">
            <div className="JudgesPage___description"><PrismicRichText field={judges.judges_content?.richText}/></div>
            <div className="JudgesPage___grid">
              {judges.judge_grid.map((item,index) => (
                <div className="JudgesPage___card" key={"judges"+index}>
                  <GatsbyImage
                    image={item.image?.gatsbyImageData}
                    alt={item.image?.alt || ""}
                    className="JudgesPage___card-image"
                  />
                  <h4>{item.name || ""}</h4>
                  <h5>{item.position || ""}</h5>
                  <PrismicLink href={item.link?.url || "#"} >{item.link_label || ""}</PrismicLink>
                  <ReadMore>{item.description || ""}</ReadMore>
                </div>
              ))}
            </div>
          </div>
        </div>
      <SliceZone slices={judges.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query judgesQuery($id: String, $lang: String) {
    prismicJudges(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        
        hero_content{
          richText
        }
        hero_image{
          gatsbyImageData(
          width: 1421
          placeholder: BLURRED
        )
          alt
        }
        hero_link_label_1
        hero_link_1{
          url
        }
        hero_link_label_2
        hero_link_2{
          url
        }
        judges_content{
          richText
        }
        judge_grid{
          image{
            gatsbyImageData
            alt
          }
          name 
          position 
          link_label 
          link{
            url 
          }
          description

        }
        read_more
        read_less
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...JudgesDataBodyJudgeCardGrid
        }
        display_title{
          text
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang}) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(JudgesTemplate)
