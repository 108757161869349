import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const TeacherChecklistTemplate = ({ data }) => {
  if (!data) return null

  const teacherContent = data.prismicTeacherChecklist
  const teacher = teacherContent.data || {}
  const display_title = teacher.display_title?.text || ""
  const { lang, type, url } = teacherContent
  const alternateLanguages = teacherContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}
  
  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="Container">
        <section className="checklist_hero">
          <div className="title">
            <PrismicRichText field={teacher.title.richText}/>
            <PrismicRichText field={teacher.subtitle.richText}/>
          </div>
        </section>
      </div>
      <SliceZone slices={teacher.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query teacher_checklistQuery($id: String, $lang: String) {
    prismicTeacherChecklist(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title{
          richText
        }
        subtitle {
          richText
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...TeacherChecklistDataBodyImageChecklist
          ...TeacherChecklistDataBodyNewsletter
        }
        display_title{
          text
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang}) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(TeacherChecklistTemplate)
