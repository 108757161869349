import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'

export const JudgeCardGrid = ({ slice }) => (
  <section className="JudgeCardGrid">
    <div className="Container">
      <h2>{slice.primary.header}</h2>
      <div className="JudgeCardGrid___card-row">
        {slice.items.map((item,index) => (
          <div className="JudgeCardGrid___card" key={slice.id + index}>
            <h4>{item.card_title}</h4>
            <p>{item.card_description}</p>
            <PrismicLink 
              href={item.card_link?.url || "#"} 
              className="button_primary">
                {item.card_link_label}
            </PrismicLink>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export const query = graphql`
  fragment JudgesDataBodyJudgeCardGrid on PrismicJudgesDataBodyJudgeCardGrid {
    id
    primary {
      header 
      
    }
    items{
        card_title
        card_description
        card_link_label
        card_link {
          url 
        }
      }
  }
`
