import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'


export const WinnerCards = ({ slice }) => {

  function hasImage(image){
    if(image){
      return <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ""} className="WinnerCards___image"/>
    }
    else return null
  }

  return (
    <section className="WinnerCards">
      <div className="Container">
        <div className="WinnerCards___header">
          <PrismicRichText field={slice.primary.section_header?.richText}/>
        </div>
        <div className="WinnerCards___card-row">
          {slice.items.map((item,index) => (
            <div className="WinnerCards___card" key={slice.id + index}>
              {hasImage(item.card_image)}
              <div className="WinnerCards___text">
                <PrismicRichText field={item.card_content.richText}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export const query = graphql`
  fragment WinnersDataBodyWinnerCards on PrismicWinnersDataBodyWinnerCards {
    id
    primary {
      section_header{
        richText
      } 
      
    }
    items{
        card_image{
          gatsbyImageData
          alt
        }
        card_content{
          richText
        }
       
      }
  }
`
