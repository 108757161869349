import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Layout } from '../components/Layout'
import { components } from '../slices'



const ModuleOverviewTemplate = ({ data, activeDocMeta }) => {
  if (!data) return null

  const moduleContent = data.prismicModuleOverview
  const module = moduleContent.data || {}
  const display_title = module.display_title?.text || ""
  const { lang, type, url } = moduleContent
  const alternateLanguages = moduleContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }

  const current_lang = (activeDoc.lang==='fr-ca')
    ? 'fr-ca'
    : 'en-ca'

  function curLangModuleOutline(site){
    if(activeDoc.lang === 'fr-ca') return "Plan du module "
    else return "Module Outline"
  }

  var lessonNum = 0
  function lessonCounter(){
    lessonNum = lessonNum+1
    return lessonNum
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <section className="module_overview">
        <div className="Container">
          <div className="wrap">
            <div className="overview_hero">
              <div className="title">
                <PrismicRichText field={module.title.richText}/>
                 <Link className="button_primary" to={module.start_button.document?.url}>
                    {module.start_button_text}
                  </Link>
              </div>
              <div className="image_box">
                <GatsbyImage
                  image={module.image?.gatsbyImageData}
                  alt=""
                  className="overview_image"
                  loading="eager"
                  objectFit="contain"
                  role="presentation"
                />
              </div>
            </div>
            <div className="time_box">
              <PrismicRichText field={module.time.richText}/>
            </div>
            <div className="overview_tabs">
              <Tabs>
                <TabList>
                  <Tab>{module.tab_title_1}</Tab>
                  <Tab>{module.tab_title_2}</Tab>
                </TabList>
                <TabPanel>
                  <PrismicRichText field={module.overview_content.richText}/> 
                </TabPanel>
                <TabPanel>
                  <PrismicRichText field={module.what_you_learn_content.richText}/>
                </TabPanel>
              </Tabs>
            </div>
            <div className="module_outline">
              <h3>{curLangModuleOutline()}</h3>
              <div className="module_list">
                {module.module_lessons_list.map((galleryItem, index) => (
                  <div className="item">
                    <div className="counter">{lessonCounter()}</div>
                    <div>
                      <PrismicRichText id="lesson_title" field={galleryItem.lesson_name.richText} />
                      <PrismicRichText field={galleryItem.lesson_time.richText} />
                    </div>
                    <div className="button_box">
                      <Link className="button_primary" to={galleryItem.lesson_button.document?.url}>
                        {module.start_button_text}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query module_overviewQuery($id: String, $lang: String) {
    prismicModuleOverview(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title{
          richText
        }
        start_button{
          document{
            ... on PrismicModuleLesson {
              id
              url
              type
            }
          }
        }
        start_button_text
        image{
          url
          alt
          gatsbyImageData (
            width: 350
            placeholder: BLURRED
          )
        }
        time{
          richText
        }
        tab_title_1
        tab_title_2
        overview_content{
          richText
        }
        what_you_learn_content{
          richText
        }
        teacher_info_content{
          richText
        }
        module_lessons_list{
          lesson_name{
            richText
          }
          lesson_time{
            richText
          }
          lesson_button{
            document{
              ... on PrismicModuleLesson {
                id
                url
                type
              }
            }
          }
        }
        display_title{
          text
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang}) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(ModuleOverviewTemplate)
