import * as React from 'react'

export const LyricsGenerator = () => {
  const [prompt, setPrompt] = React.useState('')
  const [lyrics, setLyrics] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [isCopied, setIsCopied] = React.useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch('/api/generate-lyrics', {
        method: 'POST',
        body: JSON.stringify({ prompt }),
      })
      const data = await response.json()
      setLyrics(data.lyrics)
    } catch (error) {
      console.error('Error generating lyrics:', error)
    }
    setIsLoading(false)
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(lyrics).then(() => {
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000) // Reset after 2 seconds
    })
  }

  return (
    <div className="lyrics-generator">
      <form onSubmit={handleSubmit}>
        <textarea
          className="lyrics-textarea"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter a prompt for your song lyrics..."
          rows="4"
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Generating... this usually takes 5-10 seconds!' : 'Generate Lyrics'}
        </button>
      </form>
      {lyrics && (
        <div className="generated-lyrics">
          <h2>Generated Lyrics:</h2>
          <pre>{lyrics}</pre>
          <button onClick={handleCopyToClipboard} className="copy-button">
            {isCopied ? 'Copied!' : 'Copy to Clipboard'}
          </button>
        </div>
      )}
    </div>
  )
}