import * as React from 'react'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactTemplate = ({ data }) => {
  if (!data) return null

  const contact = data.prismicContact 
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}
  const { lang, type, url } = contact || {}
  const display_title = contact.data.display_title.text || ""
  const alternateLanguages = contact.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    display_title,
  }

  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="Container form-container">
        <PrismicRichText field={contact.data.heading.richText} />
        <PrismicRichText field={contact.data.text_area.richText} />
        <form
          name="contact"
          method="post"
          action="/thankYou/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          className="wrap">

          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <div>
            <label htmlFor="name">Name: <input type="text" name="name" id="name" onChange={handleChange} /></label>   
          </div>
          <div>
            <label htmlFor="email">Email: <input type="email" id="email" name="email" onChange={handleChange} /></label>
          </div>
          <div>
            <label htmlFor="msg">Message: <textarea id="msg" name="message" onChange={handleChange}></textarea></label>
          </div>
          <button className="button_primary" type="submit">Send</button>
        </form>
      </div>  
    </Layout>
  )
}

export const query = graphql`
  query contactQuery($lang: String) {
    prismicContact(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        display_title{
          text
        }
        heading{
          richText
        }
        text_area{
          richText
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang}) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(ContactTemplate)

