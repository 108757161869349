import * as React from 'react'
import { StaticQuery,Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'


import { LanguageSwitcher } from './LanguageSwitcher'

export const TopMenu = ({ topMenu, activeDocMeta }) => {
  const image = topMenu.logo
  const lang_str = topMenu.language_header || "Language"
  const mod_str = topMenu.module || "Module"
  const current_lang = (activeDocMeta.lang==='fr-ca')
    ? 'fr-ca'
    : 'en-ca'

  var moduleNum = 0
  function moduleCounter(){
    moduleNum = moduleNum+1
    return mod_str+" "+moduleNum
  } 

  function curLangIntroLink(site, curLang){
    if(activeDocMeta.lang === 'fr-ca') return "/page/fr-ca/module-intro"
    else return "/page/module-intro"
  }

  function curLangIntro(site, curLang){
    if(activeDocMeta.lang === 'fr-ca') return "intro"
    else return "Intro"
  }

  function curLangSkip(site, curLang){
    if(activeDocMeta.lang === 'fr-ca') return "Aller au contenu"
    else return "Skip to Content"
  }

  function curLangSubmitLink(site, curLang){
    if(activeDocMeta.lang === 'fr-ca') return "https://www.rocketjudge.com/register/YVIPCanada-french"
    else return "https://www.rocketjudge.com/register/YVIPCanada-english"
  }
  
  function curLangNewsletter(site, curLang){
    if(activeDocMeta.lang === 'fr-ca') return "/fr-ca/#newsletter"
    else return "/#newsletter"
  }

  function reduced(modules){
    var info = modules.filter(function(module){
      if (module.lang === current_lang ) return true
      else return false
    })
    return info
  }
  function header_funct(homepages){
    return homepages.find(item => item.lang === current_lang);
  }
  return (
    <StaticQuery 
      query={graphql`
        query HeaderQuery {
          allPrismicModuleOverview(
            sort: {fields: uid, order: ASC}) {
            nodes {
              url
              uid
              lang
              id
            }
          }
          allPrismicHomepage {
            nodes {
              url
              lang
              id
            }
          }
        }
      `}
      render={data => (
        <header>
          <a className="skip_button" href="#main">   
            {curLangSkip()}
          </a>
          <div className="banner">
            <a className="learn-more-newsletter" href={curLangNewsletter()}>
              <PrismicText field={topMenu.banner.richText}/>
            </a>
            <a className="amazon-logo" href="https://www.amazonfutureengineer.ca" target="_blank" rel="noopener">
              <StaticImage
                src="../images/amazon_logo.png"
                alt="Amazon Future Engineer"
                width={200}
              />
            </a>
            <LanguageSwitcher activeDocMeta={activeDocMeta} Language={lang_str} />
          </div>
          <div className="menu">
            <label htmlFor="burger" className="mobile_ham">Mobile Nav Button</label>
            <input className="ham_check" type="checkbox" name="" id="burger" />
            <div className="ham_lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <div className="home_logo">
                <PrismicLink href={header_funct(data.allPrismicHomepage.nodes).url}>
                  <GatsbyImage
                    image={image?.gatsbyImageData}
                    alt={image?.alt || ""}
                    className="home_logo"
                    loading="eager"
                    placeholder="blurred"
                  />            
                </PrismicLink>
              
            </div> 
            <nav className="menu_links">
              <div className="dropdown">
                <button className="drop_btn">{topMenu.resources_header}</button>
                <div className="drop_content" role='menu'>
                  {topMenu.resources?.map((page, index) => (
                    <PrismicLink className="page_link item" href={page.item_link?.url} key={"topMenuResources-"+index}>
                      {page.item_text}
                    </PrismicLink>
                  ))}
                </div>
              </div>
              <div className="dropdown">
                <button className="drop_btn">{topMenu.modules_header}</button>
                <div className="drop_content modules">
                  <Link className="page_link item" to={curLangIntroLink()} >
                    Module {curLangIntro()}
                  </Link>
                  {reduced(data.allPrismicModuleOverview.nodes).map((module,index) => (
                    <Link className="item" to={module.url} key={"TopMenuModule-"+index}>
                      {moduleCounter()} 
                    </Link>
                  ))}
                </div>
              </div>
              <PrismicLink className="page_link" href={topMenu.contact_link?.url}>
                {topMenu.contact_header}
              </PrismicLink>
              <a href={curLangSubmitLink()} className="page_link" target="_blank" rel="noopener">{topMenu.submit_header}</a>
              <a href="https://earsketch.gatech.edu/earsketch2/" target="_blank" rel="noopener" className="button_primary button_link">{topMenu.button_header}</a>
            </nav>
          </div>
        </header>
      )}
    />
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    id
    data {
      banner {
        richText
      }
      language_header
      logo {
        url
        alt
        gatsbyImageData(
          width: 200
        )
      }
      resources_header
      modules_header
      module
      contact_header
      contact_link{
        url
        link_type
      }
      submit_header
      button_header

      resources{
        item_text
        item_link{
          url
          link_type
        }
      }
    }
    
  }
`
