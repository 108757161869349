import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

export const Teacher = ({ slice }) => {
  const image = slice.primary.image
  return (
    <section className="teacher">
      <div className="Container">
        <div className="flex_container">
          <div className="flex_image">
            <GatsbyImage
              image={image?.gatsbyImageData}
              alt={image?.alt || ""}
              className="teacher_image"
            />
          </div>
          <div className="flex_content">
            <PrismicRichText field={slice.primary.header.richText} />
            <PrismicRichText field={slice.primary.text.richText} />
            <Link className="button_primary" to={slice.primary.button.document?.url}>
              {slice.primary.button_text}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTeacher on PrismicHomepageDataBodyTeacher {
    primary {
      image{
        url
        alt
        gatsbyImageData
      }
      header {
        richText
      }
      text {
        richText
      }
      button{
        document{
          ... on PrismicTeacherChecklist {
            id
            url
            type
          }
        }
      }
      button_text
    }
  }
`
